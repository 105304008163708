import React from "react";
import style from './Cta.module.css';
import Container from "../Container/Container";
import SectionTitle from "../SectionTitle/SectionTitle";
import { useTranslation } from "react-i18next";
import SectionDesc from "../SectionDesc/SectionDesc";
import Button from "../Button/Button";
import bookImg from "../../assets/book.png"
function Cta({ctaRef}) {
  const [t] = useTranslation('common');
  const {i18n} = useTranslation('common');

  const PAYMENT_LINKS = {
    'uk': process.env.REACT_APP_INVOICE_LINK_UK,
    'en': process.env.REACT_APP_INVOICE_LINK_EN,
    'ru': process.env.REACT_APP_INVOICE_LINK_RU
  }
  return (
    <section className={style.cta} ref={ctaRef}>
      <Container relative className={style.cta__container}>
        <div className={style.cta__inner}>
          <SectionTitle secondary large className={style.cta__title}>
            {t('cta.title')}
          </SectionTitle>
          <SectionDesc large className={style.cta__desc}>
            {t('cta.desc')}
          </SectionDesc>
          <Button maxContent large to={PAYMENT_LINKS[i18n.language]}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M29.4262 18.0683H2.57638H2.57522C1.90208 18.0683 1.35589 17.5208 1.35589 16.849V14.6115C1.35589 13.9384 1.90338 13.3922 2.57522 13.3922H29.4262C30.0993 13.3922 30.6455 13.9397 30.6455 14.6115V16.849C30.6455 17.5221 30.098 18.0683 29.4262 18.0683ZM2.67406 16.749H29.3262V14.7115H2.67406V16.749Z" fill="#814624" stroke="#814624" strokeWidth="0.2" />
              <path d="M28.8663 17.3088H28.9663V17.4088C28.9663 20.7145 28.2883 23.668 27.1722 25.7988C26.0581 27.9258 24.4933 29.2559 22.7135 29.2559H9.2886H9.28628C7.50644 29.2559 5.94167 27.9258 4.82759 25.7988C3.71152 23.668 3.03348 20.7145 3.03348 17.4088V17.3088H3.13348H4.25281H4.35281V17.4088C4.35281 20.2777 4.92686 22.9199 5.83688 24.8412C6.75113 26.7714 7.98486 27.9377 9.28744 27.9377H22.7123C24.0149 27.9377 25.2487 26.7711 26.1629 24.8407C27.0729 22.9193 27.647 20.2771 27.647 17.4088V17.3088H27.747H28.8663Z" fill="#814624" stroke="#814624" strokeWidth="0.2" />
              <path d="M26.7765 16.9423L26.8472 16.8716L26.9179 16.9423L27.7086 17.733L27.7793 17.8037L27.7086 17.8744L16.5209 29.0621L16.4502 29.1328L16.3794 29.0621L15.5888 28.2715L15.5181 28.2008L15.5888 28.13L26.7765 16.9423Z" fill="#814624" stroke="#814624" strokeWidth="0.2" />
              <path d="M19.1614 16.9427L19.2321 16.872L19.3028 16.9427L20.0935 17.7334L20.1642 17.8041L20.0935 17.8748L9.66849 28.2998L9.59778 28.3705L9.52707 28.2998L8.73641 27.5091L8.6657 27.4384L8.73641 27.3677L19.1614 16.9427Z" fill="#814624" stroke="#814624" strokeWidth="0.2" />
              <path d="M12.3092 16.9427L12.3799 16.872L12.4506 16.9427L13.2412 17.7334L13.3119 17.8041L13.2412 17.8748L5.58765 25.5284L5.51694 25.5991L5.44623 25.5284L4.65557 24.7377L4.58486 24.667L4.65557 24.5963L12.3092 16.9427Z" fill="#814624" stroke="#814624" strokeWidth="0.2" />
              <path d="M5.42367 14.7117H5.32367V14.6117C5.32367 7.28772 10.0937 1.28965 16.0013 1.28965C21.9089 1.28965 26.6789 7.28772 26.6789 14.6117V14.7117H26.5789H25.4031H25.3031V14.6117C25.3031 8.04651 21.1087 2.7482 16.0013 2.7482C10.8939 2.7482 6.6995 8.04651 6.6995 14.6117V14.7117H6.5995H5.42367Z" fill="#814624" stroke="#814624" strokeWidth="0.2" />
            </svg>
            <span>
              {t('cta.btn', { price: 50 })}
            </span>
          </Button>
        </div>
        <img className={style.cta__img} src={bookImg} alt="" />
      </Container>
    </section>
  );
}

export default Cta;